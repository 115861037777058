*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}
body{
  background: var(--color-bg);
}
a{
  color: unset;
  text-decoration: none;
}
.section__padding{
  padding: 4rem 6rem;
}
.section__margin{
  margin: 4rem 6rem;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.gradient-bg{
  background: linear-gradient(0deg, rgba(35,78,74,0.88) 80%, rgba(35,78,74,0.88) 100%), url('../src/assets/banner.png');
  background-size: cover;
}

@media screen and (max-width: 700px) {
  .section__padding{
    padding: 4rem;
  } 
  .section__margin{
    margin: 4rem;
  }
  .gradient-bg{
    width: 100vw;
  }
}

@media screen and (max-width: 550px) {
  .section__padding{
      padding: 4rem 2rem;
  } 
  .section__margin{
      margin: 4rem 2rem;
  }
}