.features{
    padding: 1rem;
    background: #fff;
}
.features-container{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

@media screen and (max-width: 700px){
    .features-container{
        flex-direction: column;
    }
}