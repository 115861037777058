
.centeredPopup{
    border: 1px solid #d27e4991;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    padding: 0.5rem;
    background-color: white;
    margin-top: 2rem;
    box-shadow: 2px 5px 5px 5px #1a504b63;

    
}
.businessesEnumeration{
    overflow-y: auto;
    height: 10rem;
    width: 95%;
    /* border: 1px solid #528d91; */
    background-color: #e6f3ef;
    margin-left: auto;
    margin-right: auto;
    padding: 0.1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
}
.cancelSearchAgain{
    margin-left: 1rem;
    color: #ff5300;
    cursor: pointer;
    font-style: italic;
}
.notifHeader{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: -webkit-center;
    color: #0a75bb;
    font-weight: 300;
    font-stretch: ultra-expanded;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: small;
    background-color: #def5f4;
    border-radius: inherit;
}
.otherBiz{
    border: 1px solid #30605b7a;
    margin-top: 0.1rem;
    margin-left: auto;
    margin-right: auto;
    background: #e6efee;
    border-radius: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
    height: 1.7rem;
    font-weight: 200;
    font-size: large;
    color: #0a75bb;
    padding-left: 1rem;
    text-decoration: underline;
    padding-bottom: 0.1rem;
}
.otherBiz-wide{
    width: 100%;
    
}
.otherBusinesses{

    margin-top: 2rem;
    border: 1px solid red;

}

.centeredLayoverLogin{    
    margin: auto;
    width: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: center; /* Aligns items horizontally */
    align-items: center; /* Aligns items vertically */
    height: 100vh;
}