
.attentionIndicator{
    font-size: 20px !important;
    color: red;
    position: relative;
    margin-top: -2.6rem;
    margin-left: 130%;
    text-shadow: 1px 1px #472424;
}
.dashLabel{
    width: 100%;
}
.dashIcon{
    width: 100%;
    font-size: 32px !important;
}
.dashboardIcon:hover{
    box-shadow: 1px 1px 2px 3px #b6c3c2;
    color:#52706e;
    background-color: #9ab6b4;
    color: #273d3c;
    font-weight: 500;
}
.dashboardIcon{
    border: 1px solid #a9a9a9;
    display: flex;
    justify-content: center;
    width: 30%;
    flex-wrap: wrap;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin: 1rem;
    padding: 1.5rem;
    height: 80%;
    box-shadow: 2px 1px 2px 2px #b6c3c2;
    font-size: 1.99vh;
    background-color: #cae5e3;
    color: #153e3b;
    font-weight: 400;
}