.header{
    display: flex;
    justify-content: space-between;
}
.header-content{
    max-width: 576px;
}
.header-content h2{
    max-width: 547px;
    color: #fff;
    font-weight: 700;
    font-size: 54px;
    line-height: 67.34px;
    padding-bottom: 1rem;
}
.header-content p{
    max-width: 434px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30.24px;
    color: #fff;
    padding-bottom: 1.5rem;
}
.header-content button{
    border: none;
    border-radius: 10px;
    padding: 10px 18px;
    background: #fff;
    color: #234E4A;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.22px;
    cursor: pointer;
}
.header-content button:hover{
    border: 1px solid #fff;
    background: #234E4A;
    color: #fff;
    transition: 0.5s ease-in-out;
}

@media screen and (max-width: 700px) {
    .header{
        flex-direction: column;
        margin-top: -3rem;
    }
    .header-content h2{
        font-size: 34px;
        line-height: 47.34px;
    }
    .header-content button{
        margin-bottom: 1rem;
    }
    .header-content p{
        font-size: 18px;
        line-height: 27.24px;
    }
}