.pagingBtns:hover{
    cursor: pointer;
    font-size: smaller;
    font-weight: 200;
}
.pagingBtns{
    padding-left: 0.11rem;
    height: 1.5rem;
    text-align: center;
    padding-right: 0.11rem;
    background-color: #193e3b;
    border: none;
    border-radius: 2px;
    margin-right: 0.1rem;
    color: white;
    font-weight: 100;
    width: 1rem;
    cursor: pointer;
    font-size: x-small;
}



.newAddress {
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    cursor: pointer;
}

.newAddress :hover {
    font-weight: 300;
}


.searchInput {
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    height: 5vh;
    box-shadow: inset 0 0 1px 1px #888;
    background: #fff;
    padding: 2vh 0vh 2vh 2vh;
    font-weight: 100;

    display: flex;
    width: 228px;
    height: 40px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.searchInput:hover{
    box-shadow: inset 0 0 0.3vh 0.3vh #cbcbcb;
}

.appTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box !important;
    margin-top: 2vh;
    width: 100%;
    flex-wrap: wrap;
}

.tblInlineBtns{
    border-radius: 9px;
    border: 0.5px solid #808080;
    display: flex;
    width: 104px;
    height: 34px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    color: #808080;
    text-align: center;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    background-color: #FFF;
    cursor: pointer;
}

.tblInlineBtns:hover{
    box-shadow: inset 0 0 0.2vh 0.1vh #cbcbcb;
    font-weight: 200;
}