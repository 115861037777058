table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
  }
  tr {
    border-bottom: 1px solid #ccc;
    border-bottom: none;

    font-size: 16px;
    color: var(--Primay-green, #234E4A);
    text-align: left;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
  }
  tr.disabled {
    color: #234E4A;
  }
  tr:hover {
    color: #234E4A;
    font-weight: 300;
    cursor: pointer;
  }
  tr:not(:last-of-type) > .td {
    border-bottom: none !important;
    border-bottom: none !important;
  }
  th,
  td {
    text-align: left;
    padding: 8px;
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background-color: #fbfcfc;
  }

.cell-header{
    display: none;
}

th{
    background: #f6f8f8 ;
    color: var(--Primay-green, #234E4A);
    text-align: left;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-bottom: none;
}

@media screen and (max-width: 600px) {
    th {
      display: none;
    }
    .cell-header {
      display: block;
      font-weight: bold;
      width:50%;
    }
    td {
      display: flex;
      justify-content: space-between;
    }
    tr:nth-child(even) {
        background-color: #f6f8f8;
    }
    tr {
        text-align: left;
        padding: 2px;
        white-space: nowrap;
        border-bottom: 1px solid rgb(149, 172, 166);
    }
  }