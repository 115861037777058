.generalNotification{
    color: green;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    text-align: center;
}

.sendAgainIn{
    font-size: xx-small;
    margin-left: 0 !important;
    justify-content: left;
    width: 100%;
    text-align: center;
    color: #d3583f;
}
.fifth-and-a-half-input{

}
.otpLabel{
    margin-right: 1rem;
}
.otpDiv{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    width: 100%;
}
.send-otp-div-disabled{
    cursor: none;
    color: #8b8ea7;
    display:none;
}
.send-otp-div:hover{
    color: #f2775f;
    cursor: pointer;
    text-decoration: underline;
}
.send-otp-div{
    margin-right: 1rem;
    color: #d84d32;
    font-size: smaller;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    display: flex;
}
.otp-input{
    width: 170px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    padding: 10px;
    outline: none;
    margin-top: 0.5rem;
}
.requestSubmitConfirm{
    color: #ec6e3b;
    margin-left: 0;
    margin-right: 0;
    text-align: -webkit-center;
    margin: 0.5rem;
    font-weight: 300;
    border: 1px solid #d8ececbd;
    border-radius: 8px;
    background-color: #f5f9fd;
    box-shadow: 1px 1px 2px 2px #eaf1f0;
    padding: 0.2rem;
    display: none;
}
.getStartedloaderEl{
    fill: rgb(48, 96, 91);
    height: 40px !important;
    width: 40px !important;
    display: flex;
    margin: 0 auto;
}
.errorDiv{
    color: red;
    font-weight: 100;
    font-size: small;
    height: 6px;
}
.forms{
    display: flex;
    flex-direction: column;
    max-width: 520px;
    background: #fff;
    border: 1px solid #D44F28;
    padding: 1rem;
    border-radius: 10px;
}
.forms h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 60.48px;
    color: #D44F28;
    text-align: center;
}
.first-inputs, .third-inputs, .fourth-inputs{
    display: flex;
    align-items: center;
    gap: 1rem;
}
#name, #business, #amount, .datalist-input input, #number{
    width: 228px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    padding: 10px;
    outline: none;
    margin-top: 0.5rem;
}
.second-input, .third-inputs,
.fourth-inputs, .fifth-input,
.sixth-input, .form-btn
{
    margin: 0.5rem 0;
}
#email, .fifth-input input{
    width: 470px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    padding: 10px;
    outline: none;
    margin-top: 0.5rem;
}
.sixth-input{
    display: flex;
    gap: 0.5rem;
}
.sixth-input label{
    font-weight: 300;
    font-size: 12px;
    line-height: 18.14px;
}
.form-btn button{
    width: 470px;
    border: none;
    background: #234E4A;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}

@media screen and (max-width: 700px){
    .forms h2{
        font-size: 30px;
        line-height: 60.48px;
        color: #D44F28;
        text-align: center;
    }
    .first-inputs, .third-inputs, .fourth-inputs{
        flex-direction: column;
    }
    #email, .fifth-input input{
        width: 228px;
    }
    .second-input, .fifth-input{
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        /* padding-left: 2.1rem; */
    }
    .form-btn{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .form-btn button{
        width: 250px;
    }
}