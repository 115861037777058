.card{
    width: 286px;
    height: 313px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    margin: 1rem 0;
    border-radius: 12px;
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
}
.card-content h2{
    width: 247px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22.68px;
    color: #D44F28;
    padding-top: 0.5rem;
}
.card-content p{
    width: 247px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.68px;
    color: #1A1A1A;
    padding-top: 1rem;
}

@media screen and (max-width: 700px){
    .card{
        width: 260px;
        margin: 0 auto;
    }
    .card-content h2{
        width: 220px;
        font-size: 13px;
        line-height: 22.68px;
    }
    .card-content p{
        width: 220px;
        font-size: 13px;
    }
}