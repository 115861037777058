.subscribe{
    width: 100%;
    margin: 3.5rem auto 0;
    padding: 3rem;
    background: linear-gradient(0deg, rgba(35,78,74,0.9) 80%, rgba(35,78,74,0.95) 100%), url('../../../src/assets/cargo.png');
    background-position: center;
    background-size: cover;
}
.subscribe-content{
    width: 90%;
    padding: 0 2rem;
    margin: 0 auto;
}
.subscribe-content p{
    font-weight: 600;
    font-size: 30px;
    line-height: 48.38px;
    text-align: center;
    color: #fff;
    padding: 0 2rem;
}
.subscribe-btn{
   display: flex;
   justify-content: center;
   margin-top: 1rem;
}
.subscribe-btn button{
    border: none;
    background: #fff;
    padding: 10px 25px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    color: #234E4A;
    cursor: pointer;
}

@media screen and (max-width: 700px){
    .subscribe-content{
        width: unset;
        padding: 0 1rem;  
    }
    .subscribe-content p{
        font-size: 18px;
        line-height: 27.38px;
        padding: 0 1rem;
    }
}
