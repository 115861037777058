/*@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700;800&display=swap');
html {
  position: relative;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body { 
  font-family: "Prompt", sans-serif;
  font-weight: lighter;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  
  position: relative;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  overflow: auto;
}

.gradient-bg{
  background: linear-gradient(0deg,rgba(35,78,74,.88) 80%,rgba(35,78,74,.88) 100%),url(../images/banner.png);
  background-size: cover;
}
*/
/*@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700;800&display=swap');
*/
:root {
  --font-family: 'Prompt', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #F6F8F8;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}