.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
}
.footer-heading{
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}
.footer-heading h1{
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    text-align: center;
    color: #1A1A1A;
}
.footer-input{
    display: flex;
    align-items: center;
    position: relative;
}
.footer-input input{
    width: 470px;
    height: 64px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    outline: none;
}
.footer-input span p{
    position: absolute;
    background: #234E4A;
    top: 10%;
    right: 5%;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    color: #fff;
    cursor: pointer;
}
.footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding: 5rem;
}
.footer-links div{
    width: 200px;
}
.first-column{
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
}
.first-column img{
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
    object-fit: cover;
}
.first-column p{
    font-weight: 400;
    font-size: 15px;
    line-height: 22.68px;
    text-align: justify;
}
.footer-links_div{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.footer-links_div p{
    font-weight: 500;
    font-size: 16px;
    line-height: 24.19px;
    color: #234E4A;
    padding-bottom: 2rem;
    cursor: pointer;
}
.last-column{
    padding-top: 1rem;
}
.last-column h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 24.19px;
    padding-bottom: 1.5rem;
}
.social-links{
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
}
.social-links p{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #234E4A;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
}

@media screen and (max-width: 700px){
    .footer-input{
        max-width: 300px;
    }
    .footer-input input{
        position: relative;
        max-width: 100%;
        margin: 0 auto;
    }
    .footer-input span p{
        top: 1rem;
        right: 1rem;
        padding: 5px 15px;
        font-size: 14px;
    }
    .footer-links{
        padding: 2rem 1.5rem;
    }
    .first-column{
        padding-bottom: 1.5rem;
    }
    .footer-links_div{
        margin-bottom: 1rem;
    }
    .footer-links_div p{
        padding-bottom: 1rem;
    }
}