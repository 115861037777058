.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}
.navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.navbar-logo{
    margin-right: 4rem;
}
.navbar-logo img{
    cursor: pointer;
    /* width: 62.56px;
    height: 16.02px; */
}
.navbar-links_container{
    display: flex;
    flex-direction: row;
}
.navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar-links_container p{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
.navbar-sign button{
    padding: 0.3rem 2rem;
    color: #fff;
    background: #D44F28;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 27.22px;
    border-radius: 5px;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
}
.navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}
.navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #fff;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}
.navbar-menu_container p{
    color: #234E4A;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
.navbar-menu_container button{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}
.navbar-menu_container p{
    margin: 1rem 0;
}
.navbar-menu_container-links-sign{
    display: none;
}

@media screen and (max-width: 1050px) {
    .navbar-links_container{
        display: none;
    }
    .navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar{
        padding: 2rem 4rem;
    }
    .navbar-logo img{
        width: 150.56px;
        height: 26.02px;
        object-fit: cover;
    }
}

@media screen and (max-width: 550px) {
    .navbar{
        padding: 2rem;
    }
    .navbar-sign{
        display: none;
    }
    .navbar-menu_container{
        top: 20px;
    }
    .navbar-menu_container-links-sign{
        display: block;
    }
}