.step{
    display: flex;
    align-items: center;
    gap: 0.6rem;
    width: 341px;
    margin: 1rem 0;
}
.step-number{
    display: block;
    margin: 0 auto;
}
.step-number h2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 27.22px;
    background: #fff;
    color: #234E4A;
    padding: 1rem;
}
.step p{
    width: 244px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.68px;
    color: #fff;
}

@media screen and (max-width: 700px) {
    .step{
        display: flex;
        align-items: center;
        gap: unset;
        width: 300px;
        margin: 1rem 0;
    }
    .step p{
        width: 220px;
        font-weight: 400;
        font-size: 13px;
        line-height: 22.68px;
        color: #fff;
    }
    
}