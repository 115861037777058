
.regcodeDiv{
    color: #273d3c;
    font-weight: 500;
}
.clickableTableItem{
    color: #0e2321;
    text-decoration: underline;
    font-weight: 400;
    text-decoration-line: overline;
}
.bizNameSpanInTbl{
    text-decoration: underline;
}
.businessNamesDiv{
    display: flex;
    flex-wrap: wrap;
}
.biznameTableListing:hover{
    font-weight: 300;
    font-style: italic;
    cursor: pointer;
}
.biznameTableListing{
    font-weight: 200;
    font-size: small;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.clickable:hover{
    cursor: pointer;
}
.dasbhoard-form-header{
    width: 100%;
    text-align: center;
    color: #193e3b;
    font-weight: 300;
    font-size: x-large;
}
.searchStrip{
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    align-content: baseline;
}
.rootEl{
    padding: 1%;
    display: flex;
    background: var(--Background, #F6F8F8);
    width: 100%;
    height: 1024px;
}

.userDetailsStrip{
    display: flex;
    width: 100%;
    height: 60px;
    padding: 0px 32px 0px 25px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: var(--primary-white, #FFF);
}

.appContainer{
    width: 100%;
    min-height: 833px;
    flex-shrink: 0;
    border-radius: 12px;
    padding: 2%;
    background-color: white;
}

.leftMenu{
    padding: 1%;
    width: 14%;
    height: 915px;
    background: var(--Background, #F6F8F8);
    /*border: 1px solid gray;*/
}

.mainPanel{
    /*padding: 1%;*/
    width: 85%;
    height: 100%;
    background: var(--Background, #F6F8F8);
    /*border: 1px solid gray;*/
}
.notifSvg{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.userImg{
    width: 32px;
    height: 32px;
    border-radius: 32px;
    /*background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;*/
}
.seperatorDiv{
    align-items: center;
    gap: 14px;
}
.userImgNotifSection{
    display: flex;
    align-items: center;
    gap: 14px;
   
    /*width: 100px;
    height: 39px;
    border: 1px solid red;*/
}

.crobodLogo{
    /*padding-left: 11%;*/
    width: 116px;
    height: 40px;
    flex-shrink: 0;
    background: url('../../images/crobod-white-no-background.png');
    cursor: pointer;
}



.navLocationDiv{
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}



.appCardContainer{
    box-sizing: content-box !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /*border: 1px solid red;*/
}

.chatFillPlus{
    display: flex;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    fill: var(--Background, #F6F8F8);    
}

.appCardText{ 
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.appCardText:hover{ 
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 200;
    text-shadow: 2px 8px 22px 2px rgba(0, 0, 0, 0.05);
    line-height: normal;
}

.appCardItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    width: 90px;
    height: 145px;
    padding: 19px 64px 13px 64px;
    border-radius: 9px;
    /*border: 2px solid var(--Secondary-color-orange, #D44F28);*/
    background: var(--Primary-White, #FFF);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
}

.logoutButton{
    display: flex;
    width: 129px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: #FFF;
    cursor: pointer;
    color: var(--Secondary-color-orange, #D44F28);
    font-family: 'Prompt';
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: auto;
}

.userNameField{
    font-weight: 100;
    font-size: 1.1vw;
}

.logoutButton :hover{
    font-weight: 400;
    text-shadow: 2px 8px 22px 2px rgba(0, 0, 0, 0.05);
}



.menuItems{
    
}

.menuItems li{
    list-style-type: none;
}


.menuItemActive {
    width: 149px;
    margin-top: 2%;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    text-decoration: none;
    font-weight: 100;
    word-wrap: break-word ;
    color: var(--Primary-White, #FFF) ;
    font-family: 'Prompt' ;
    font-size: 15px ;
    font-style: normal ;
    line-height: normal ;
    border-radius: 9px ;
    background: var(--Primay-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #234E4A) ;
    padding: 5px 30px 5px 10px ;
    display: block ;
}

.menuItemInactive {
    width: 149px;
    margin-top: 2%;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    text-decoration: none;
    word-wrap: break-word ;
    line-height: normal ;
    border-radius: 9px ;
    padding: 5px 30px 5px 10px ;
    display: block ;
    color: var(--Primay-green, #234E4A);
    font-family: 'Prompt';
    font-size: 15px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}

.menuItemActive:hover {
    font-weight: 200;
}
.menuItemInactive:hover {
    font-weight: 200;
    background: var(--Primay-green, linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), #F6F8F8) ;
}