.feature{
    width: 410px;
    height: 231px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    margin: 1rem auto;
    border-radius: 12px;
    box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
}
.feature-content h2{
    width: 247px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22.68px;
    color: #D44F28;
    padding-bottom: 0.5rem;
}
.feature-content p{
    width: 380px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.68px;
    color: #1A1A1A;
    padding-top: 0.5rem;
}

@media screen and (max-width: 700px){
    .feature{
        width: 280px;
    }
    .feature-content p{
        width: 250px;
        font-size: 13px;
    }
}