.process{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #234E4A;
    width: 100%;
}
.process-heading{
    padding: 3rem 0;
    width: 40%;
    margin: 0 auto;
}
.process-heading h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 48.38px;
    text-align: center;
    color: #fff;
    padding-bottom: 0.8rem;
    border-bottom: 5px solid #fff;
}
.process-img img{
    width: 100%;
}
.process-text{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem;
}

@media screen and (max-width: 700px) {
    .process-text{
        grid-template-columns: repeat(1, 1fr);
        /* padding: unset; */
    }
    .process-heading{
        width: 50%;
    }
    .process-heading h2{
        font-size: 18px;
        line-height: 28.38px;
        padding-bottom: 0.5rem;
    }
}