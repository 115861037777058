.centeredLayover{
    position: fixed;
    background: #36605b;
    width: 100%;
    z-index: 100;
    height: 100%;
    opacity: 0.9;
    box-sizing: border-box;
    display: flex;
    top: 0;
}
.loadingEl{
    margin: auto;
    
}
.loadingMsg{
    color: white;
    font-weight: 200;
    font-size: small;
}
.loaderEl{
    margin:auto;
    height: 3.5rem !important;
}
.postRegLogin{
    color:#e6e6e68a;
    font-style: italic;

}
.postRegLogin:hover{
    cursor: pointer;
    font-weight: 300;
}