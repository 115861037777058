.checklist{
    width: 85%;
    margin: 0 auto;
    padding-top: 3.5rem;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
}
.checklist-content{
    padding: 0 2rem;
}
.checklist-content h3{
    font-weight: 600;
    font-size: 15px;
    line-height: 22.68px;
    color: #D44F28;
    padding-bottom: 0.5rem;
}
.checklist-content img{
    padding-bottom: 0.5rem;
}
.checklist-content p, ul li{
    font-weight: 400;
    font-size: 15px;
    line-height: 22.68px;
}
.checklist-content ul{
    padding-left: 1.8rem;
}
.checklist-content p:last-child{
    padding: 1.5rem 0;
}

@media screen and (max-width: 700px){
    
}