@media screen and (min-width: 600px) {
    
    .formButtonStyle:hover{
        width: 100%;
        text-align: center;
        border: 2px solid rgb(255, 255, 255);
        font-weight: 100;
        color: rgb(236, 239, 239);
        padding: 1px;
        border-radius: 0.4rem;
        background: rgb(54, 96, 91);
        box-shadow: rgba(54, 96, 91, 0.5) 0.01rem 0.05rem 0.375rem 0.09rem;
        height: 8vh;
        font-size: xx-large;
    }
    .formButtonStyle{
        width: 100%;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgb(255, 255, 255);
        font-weight: 100;
        color: rgb(236, 239, 239);
        padding: 1px;
        border-radius: 0.4rem;
        background: rgb(54, 96, 91);
        box-shadow: rgba(54, 96, 91, 0.36) 0.01rem 0.05rem 0.375rem 0.09rem;
        height: 8vh;
        font-size: xx-large;
    }
    .loginPage{
        background: linear-gradient(0deg,rgba(35,78,74,.88) 80%,rgba(35,78,74,.88) 100%),url(../../../images/banner.png);
        background-size: cover;  
    
        position: fixed;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        overflow: hidden;
        overflow: auto;
    
        height: 1000px;
    
        display: flex;
        justify-content: center; /* Aligns items horizontally */
        align-items: center; /* Aligns items vertically */
        height: 100vh;
    }
    
    .formButtonz{
        padding: 1px;
        box-sizing: border-box;
        width: 80%;
        margin: 0 auto;
    }
    .formBody {
        background-color: white;
        width: 85%;
        /*height: 50%;*/
        box-shadow: rgba(177, 211, 207, 0.12) 0px 0px 10px 3px;
        border: 2px solid rgb(248, 130, 76);
        border-radius: 0.6rem;
        padding: 5px;
        box-sizing: border-box;
    }


    .LoginHeader{  
            
        background: rgb(255, 255, 255);
        width: 90%;
        margin: 1.05rem auto 1.5rem;
        box-shadow: rgba(255, 255, 255, 1) 0px 0px 2px 2px;
        border-radius: 0.3rem;
        font-weight: 500;
        font-size: xx-large;
        padding: 0.09rem;
        margin-right: auto;
        margin-left:auto;
        color: rgb(246 254 252);
        text-align: center;
        background-color: #33605b;
    }

}

