.register{
    background: #234E4A;
    width: 85%;
    max-height: 379px;
    margin: 5rem auto 0;
    border-radius: 12px;
}
.register-content{
    display: flex;
    padding: 2rem;
}
.register-text h2{
    font-weight: 700;
    font-size: 32px;
    line-height: 48.38px;
    color: #fff;
}
.register-text p{
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    color: #fff;
    padding: 2rem 0;
}
.register-text button{
    border: none;
    background: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27.22px;
    color: #234E4A;
    cursor: pointer;
}


@media screen and (max-width: 700px){
    .register-text h2{
        font-size: 22px;
        line-height: 28.38px;
        text-align: center;
    }
    .register-text p{
        font-size: 14px;
        line-height: 17.22px;
        color: #fff;
        padding: 1rem 0;
        text-align: center;
    }
    .register-text button{
        margin: 0 auto;
    }
    .register-content img{
        display: none;
    }
}