@media screen and (min-width: 992px) {

    .loginPage{
        background: linear-gradient(0deg,rgba(35,78,74,.88) 80%,rgba(35,78,74,.88) 100%),url(../../../images/banner.png);
        background-size: cover;  
    
        position: fixed;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        overflow: hidden;
        overflow: auto;
    
        height: 1000px;
    
        display: flex;
        justify-content: center; /* Aligns items horizontally */
        align-items: center; /* Aligns items vertically */
        height: 100vh;
    }

    .error_{
        color: red;
        font-weight: 100;
    }
    .searchTermSpan{
        color: red;
        font-weight: 300;
    }
    .loginYesLink{
        color: green;
        font-style: italic;
        font-stretch: condensed;
        font-weight: 400;
        cursor: pointer;
    }
    
    .loginNoLink{
        color: red;
        font-style: italic;
        font-stretch: condensed;
        font-weight: 400;
        cursor: pointer;
    
    }
    .bizLink{
        color: #1a73c0;
        font-weight: 500;
        font-style: italic;
        cursor: pointer;
    }
    .formInput{
        font-size: large;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid rgb(57, 96, 91);
        border-left: none;
        border-image: initial;
        width: 100%;
        height: 2.5rem;
        font-weight: 500;
    }
    .formInput:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    input[type=email]:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    input[type=password]:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    .formField{
        margin-bottom: 1rem;
        margin-top: 1px;
        padding: 5px;
        box-sizing: border-box;
        width: 100%;
    }
    .LoginHeader{  
            
        background: rgb(255, 255, 255);
        width: 50%;
        margin: 1.05rem auto 1.5rem;
        box-shadow: rgba(255, 255, 255, 1) 0px 0px 2px 2px;
        border-radius: 0.3rem;
        font-weight: 500;
        font-size: xx-large;
        padding: 0.09rem;
        margin-right: auto;
        margin-left:auto;
        color: rgb(246 254 252);
        text-align: center;
        background-color: #33605b;
    }
    
    .formBody{
    
        background-color: white;
        width: 50%;
        /*height: 50vh;*/
        /*margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto;*/
        box-shadow: rgba(177, 211, 207, 0.12) 0px 0px 10px 3px;
        border: 2px solid rgb(248, 130, 76);
        border-radius: 0.6rem;
        padding: 5px;
        box-sizing: border-box;
    }
    
    .formDiv{
    
        margin-top: 0px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .formInput{
        font-size: large;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid rgb(57, 96, 91);
        border-left: none;
        border-image: initial;
        width: 100%;
        height: 2.5rem;
        font-weight: 500;
    }
    .formInput:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    
    input[type=email]:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    
    input[type=password]:focus {
        outline: none ;
        background-color: #39605b05 !important;
    }
    button:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
    .formButtonz{
        flex-flow: column;
        padding: 1px;
        box-sizing: border-box;
    }
    .formButtonStyle:hover{
        text-align: center;
        border: 2px solid rgb(255, 255, 255);
        font-weight: 700;
        color: rgb(236, 239, 239);
        width: 8.6rem;
        padding: 1px;
        height: 2.2rem;
        border-radius: 0.4rem;
        background: rgb(54, 96, 91);
        box-shadow: rgba(54, 96, 91, 0.5) 0.01rem 0.05rem 0.375rem 0.09rem;
        height: 8vh;
    }
    .formButtonStyle{
        width: 100%;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgb(255, 255, 255);
        font-weight: 100;
        color: rgb(236, 239, 239);
        padding: 1px;
        border-radius: 0.4rem;
        background: rgb(54, 96, 91);
        box-shadow: rgba(54, 96, 91, 0.36) 0.01rem 0.05rem 0.375rem 0.09rem;
        height: 8vh;
        font-size: xx-large;
    }

}

