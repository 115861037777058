.noFinance{
    font-weight: 300;
}
.nofinanceDiv{
    width: 100%;
    height: 10%;
    padding: 5%;
    border: 1px solid #7f9392;
    border-radius: 10px;
    background: #f6f8f8;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
}
.centeredContent{
    margin: 0 auto;
    margin-top: 3%;
}
.reqFinanceButton{
    border: none;
    border-radius: 5px;
    width: 150px;
    background: #193e3b;
    color: white;
    padding: 5px;
    font-weight: 400;
    cursor: pointer;
}