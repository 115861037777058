@media screen and (min-width: 600px) {
    
    .signupHeaderIndex{  
        background: rgb(255, 255, 255);
        width: 90%;
        margin: 1.05rem auto 1.5rem;
        box-shadow: rgba(255, 255, 255, 1) 0px 0px 2px 2px;
        border-radius: 0.3rem;
        font-weight: 500;
        font-size: xx-large;
        padding: 0.09rem;
        margin-right: auto;
        margin-left:auto;
        color: rgb(246 254 252);
        text-align: center;
        background-color: #33605b;
    }
    
    .formBodyIndex{
        background-color: white;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        box-shadow: rgba(177, 211, 207, 0.12) 0px 0px 10px 3px;
        border: 2px solid rgb(231, 118, 66);
        border-radius: 0.6rem;
        padding: 5px;
        box-sizing: border-box;
    }

}

