.businessDashboardPanel{
    width:100%;
    height: 70%;
    display:flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 5%;
    margin-top: 1rem;
    border: 1px solid #7f9392;
    background: #f6f8f8;
    border-radius: 10px;
}