.searchDiv {
    display: flex;
    height: 54px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}
.appCardContainer{
    box-sizing: content-box !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /*border: 1px solid red;*/
}
.appCardItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    width: 253px;
    height: 201px;
    padding: 19px 64px 13px 64px;
    border-radius: 9px;
    /*border: 2px solid var(--Secondary-color-orange, #D44F28);*/
    background: var(--Primary-White, #FFF);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
}
.chatFillPlus{
    display: flex;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    fill: var(--Background, #F6F8F8);    
}
.appCardText{ 
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}
.appCardText:hover{ 
    color: var(--Primay-green, #234E4A);
    text-align: center;
    font-family: 'Prompt';
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 200;
    text-shadow: 2px 8px 22px 2px rgba(0, 0, 0, 0.05);
    line-height: normal;
}
.searchStrip{
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    align-content: baseline;
    margin-top: 1rem;
}
.appTable{

}