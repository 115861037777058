.fieldError{
    color:red;
    font-weight: 100;
    font-size: small;
}
.formDivIndex{
    margin-top: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
}
.welcomespantext{
    font-weight: 100;
}
.topLoginButton{
    opacity: 1;
    background: rgba(54, 96, 91, 0.09);
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    font-weight: 300;
    color: rgb(54, 96, 91);
    width: 2.6rem;
    padding: 1px;
    height: 1rem;
    border-radius: 0.4rem;
    box-shadow: rgb(54, 96, 91) 0.01rem 0.05rem 0.375rem 0.09rem;
    font-size: small;
    margin-top: 2px;
}
.welcomeSpan{
    opacity: 1;
    background-color: #dbe8e7;
    color: rgb(134, 148, 146);
    font-weight: 500;
    padding-right: 1rem;
    width: 20rem;
    margin: 0px;
    text-align: end;
    padding-top: 0.3rem;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.formButtonStyleIndex:hover{
    text-align: center;
    border: 2px solid rgb(255, 255, 255);
    font-weight: 700;
    color: rgb(236, 239, 239);
    width: 8.6rem;
    padding: 1px;
    height: 2.2rem;
    border-radius: 0.4rem;
    background: rgb(54, 96, 91);
    box-shadow: rgba(54, 96, 91, 0.5) 0.01rem 0.05rem 0.375rem 0.09rem;
}
.formButtonStyleIndex{
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    font-weight: 700;
    color: rgb(236, 239, 239);
    width: 8.6rem;
    padding: 1px;
    height: 2.2rem;
    border-radius: 0.4rem;
    background: rgb(54, 96, 91);
    box-shadow: rgba(54, 96, 91, 0.36) 0.01rem 0.05rem 0.375rem 0.09rem;
}
.crobodButonz{
    text-align: center;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    font-weight: 700;
    color: rgb(236, 239, 239);
    width: 8.6rem;
    padding: 1px;
    height: 2.2rem;
    border-radius: 0.4rem;
    background: rgb(54, 96, 91);
    box-shadow: rgba(54, 96, 91, 0.36) 0.01rem 0.05rem 0.375rem 0.09rem;
}
.formButtonzIndex{
    flex-flow: column;
    padding: 1px;
    box-sizing: border-box;
}
.buttonzDivIndex{
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    margin-left:auto;
    margin-right:auto;
}
.formInputIndex{
    font-size: large;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(57, 96, 91);
    border-left: none;
    border-image: initial;
    width: 100%;
    height: 2.5rem;
    font-weight: 500;
}
.formInputIndex:focus {
    outline: none ;
    background-color: #39605b05 !important;
}

input[type=email]:focus {
    outline: none ;
    background-color: #39605b05 !important;
}

input[type=password]:focus {
    outline: none ;
    background-color: #39605b05 !important;
}
.formLabelIndex{
    font-weight: 200;
    width: 100%;
    font-size: large;
}
.formFieldIndex{
    margin-bottom: 0.1rem;
    margin-top: 0.001rem;
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
}


.sibnupButtonDivIndex{
    opacity: 1;
    /*background-color: white;*/
    flex-basis: min-content;
    width: 100%;
    display: flex;
}
.signupLoginBanner{
    background: rgb(57, 96, 91);
    width: 100%;
    margin: 0.05rem auto 1.5rem;
    box-shadow: rgba(4, 27, 24, 0.21) 0px 0px 10px 3px;
    border-radius: 5px;
    font-weight: 400;
    font-size: xx-large;
    padding: 0.09rem;
    color: rgb(241, 255, 253);
    text-align: center;
}

.signupTopBannerIndex{
    
    background-color: #dbe8e7;
    height: 3rem;
    flex-flow: row-reverse wrap;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: rgb(24 64 59) 0px 0px 10px 3px;
    border: 1px solid rgb(57, 96, 91);
    border-radius: 5px;   
}

.signupSectionIndex{
    
    font-family: Prompt, sans-serif;
    padding: 0.7rem; 
}

.signupPageIndex{
    background: linear-gradient(0deg,rgba(35,78,74,.88) 80%,rgba(35,78,74,.88) 100%),url(../../../images/banner.png);
    background-size: cover;  

    position: fixed;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    overflow: auto;

    display: flex;
    justify-content: center; /* Aligns items horizontally */
    align-items: center; /* Aligns items vertically */
    height: 100vh;
}

.signupButton{
    padding: 0.3rem 2rem;
    color: #fff;
    background: #D44F28;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 27.22px;
    border-radius: 5px;
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
}


