.cards{
    padding-top: 2.5rem;
}
.section__padding {
    padding: 1rem 1rem; 
}
.cards-heading h2{
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 48.38px;
    padding-bottom: 2rem;
}
.cards-container{
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
}

@media screen and (max-width: 700px) {
    .cards-heading h2{
        font-size: 25px;
        line-height: 38.38px;
    }
    .cards-container{
        flex-direction: column;
        margin: 0 auto;
    }
}