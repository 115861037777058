.saveStatusSpan{
   color: #193f3c;
   font-weight: 300;
   display: none;
}
.shipperForm{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.shipperFormHeader{
    width: 100%;
    text-align: center;
    color: #193e3b;
    font-weight: 300;
    font-size: x-large;
}