.formHeader{
    width: 100%;
    text-align: center;
    color: #193e3b;
    font-weight: 300;
    font-size: x-large;
}
.formLabels{
    width: 100%;
}
.formError{
    font-weight: 300;
    color: red;
    font-size: smaller;
}
.btns{
    justify-content: space-between;
    display: flex;
    align-items: end;
}
.cancelBtn:hover{
    background-color: #F6F8F8;
    width: 25%;
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    box-shadow: 2px 2px 2px 1px #10343087;
    font-weight: 300;
    cursor: pointer;
}
.cancelBtn{
    background-color: #F6F8F8;
    width: 25%;
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    box-shadow: 2px 2px 2px 1px #10343087;
    font-weight: 200;
}
.formInputs{
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 40px;
    padding: 0.5rem;
}
.saveBtn:hover{
    background-color: #234E4A;
    color: white;
    width: 25%;
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    box-shadow: 2px 2px 2px 2px #10343087;
    font-weight: 300;
    cursor: pointer;
}
.saveBtn{
    background-color: #234E4A;
    color: white;
    width: 25%;
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    box-shadow: 2px 2px 2px 1px #10343087;
    font-weight: 200;
}
.formFieldInput{
    margin: 0.5rem;
    width: 100%;
}
.internationalAddressForm{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}